<template>
  <!-- 课程报名 -->
  <div class="container">
    <el-table
      ref="singleTable"
      :data="tableData"
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="开课时间">
        <template slot-scope="scope">
          <span>{{ scope.row.start_time | dataformat }}</span>
          <span>~</span>
          <span>{{ scope.row.end_time | dataformat1 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="limit_count" label="报名人数上限" align="center" />
      <el-table-column prop="is_join" label="报名状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.is_join">已报名</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="signUp(scope.row)"
            v-if="!scope.row.is_join"
            :disabled="!scope.row.isOrNotHighlight || scope.row.otherSign"
            >报名</el-button
          >
          <el-button type="text" @click="cancelSignUp(scope.row)" v-else
            >取消报名</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      ref="customSignUp"
      class="customSignUp"
      :width="upWidth"
      title="课程报名"
      :visible.sync="visible"
      @close="closeSignUp"
    >
      <div class="dialogbody">
        <el-form
          ref="formSignUp"
          :model="formSignUp"
          label-position="left"
          label-width="100px"
        >
          <el-form-item label="报名开课时间:">
            <span>{{ formSignUp.start_time | dataformat }}</span>
            <span>~</span>
            <span>{{ formSignUp.end_time | dataformat1 }}</span>
          </el-form-item>
          <el-form-item label="联系电话:">
            <el-input
              v-model="formSignUp.phone"
              maxlength="11"
              placeholder="请输入联系电话"
              @blur="changePhone()"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialogfooter">
        <el-button size="medium" @click="closeSignUp()">取消</el-button>
        <el-button size="medium" type="primary" @click="submit()"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import { validatePhone } from "@/utils/validate";
import dayjs from "dayjs";
import {
  enterCourse,
  cancelEnterCourse,
  getCourseDateList,
} from "@/api/course";
export default {
  name: "courseregistration",
  components: {},
  data() {
    return {
      token: getToken() ? true : false,
      tableData: [],
      visible: false,
      formSignUp: {
        start_time: "",
        end_time: "",
        phone: "",
        course_id: "",
        id: "",
      },
      courseKey: "",
      verify: true,
      upWidth: "",
    };
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm");
    },
    dataformat1(value) {
      if (!value) return "";
      return dayjs.unix(value).format("HH:mm");
    },
  },
  watch: {
    tableData: {
      handler(newV) {
        if (!newV.length) return;
        let condition = newV.some((item) => item.is_join === 1);
        if (condition) {
          newV.forEach((item) => {
            item.otherSign = true;
          });
        } else {
          newV.forEach((item) => {
            item.otherSign = false;
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this._isMobile()) {
      this.upWidth = 100 + "%";
    } else {
      this.upWidth = 40 + "%";
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    initdata(obj, courseKey) {
      if (courseKey) {
        this.courseKey = courseKey;
        this.getList(courseKey);
      }
    },
    getList(data) {
      getCourseDateList({ course_semester_sn : data })
        .then((res) => {
          if (res.code === 0) {
            if (res.data.length) {
              res.data.forEach((item) => {
                item.isOrNotHighlight = true;
                if (item.join_count >= item.limit_count) {
                  item.isOrNotHighlight = false;
                }
              });
            }
            let arr = res.data;
            this.tableData = arr.sort(function (a, b) {
              return a.start_time - b.start_time;
            });
          }
        })
        .catch(() => {});
    },
    signUp(row) {
      if (this.token) {
        this.formSignUp = {
          start_time: row.start_time,
          end_time: row.end_time,
          course_id: row.course_id,
          id: row.id,
        };
        this.visible = true;
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
    cancelSignUp() {
      const that = this;
      this.$confirm("确认要取消报名该课程吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelEnterCourse({ course_semester_sn: that.courseKey })
          .then((res) => {
            if (res.code === 0) {
              that.$message.success(res.message);
              that.getList(that.courseKey);
            }
          })
          .catch((error) => {
            //console.log(error)
          });
      });
    },
    submit() {
      if (!this.verify) {
        this.$message.warning("提交的手机号码格式不正确, 请重新输入!");
        return false;
      }
      let params = {
        course_semester_sn: this.courseKey,
        phone: this.formSignUp.phone,
        course_join_id: this.formSignUp.id,
        type: true,
      };
      enterCourse(params)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(res.message);
            this.getList(this.courseKey);
          }
        })
        .catch((error) => {
          //console.log(error)
        });
      this.visible = false;
    },
    closeSignUp() {
      this.formSignUp = {
        start_time: "",
        end_time: "",
        phone: "",
        course_id: "",
        id: "",
      };
      this.visible = false;
    },
    changePhone() {
      this.verify = this.checkModbile(this.formSignUp.phone);
    },
    checkModbile(mobile) {
      if (!validatePhone(mobile)) {
        return false;
      }
      return true;
    },
  },
};
</script>
